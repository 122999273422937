import React, {useEffect, useState} from "react";
import {Avatar, Button, Grid, Stack, Typography} from "@mui/material";
import Spline from '@splinetool/react-spline';
import appLogo from "../assets/app_logo.png"
import githubIcon from "../assets/github_icon.png"
import discordIcon from "../assets/discord_icon.png"
import androidIcon from "../assets/android_icon.png"
import appstoreIcon from "../assets/appstore_icon.png"
import updateicon from "../updateicon";

const textContent = {
    en:{
        title: "SchoolBusTime Homepage",
        subTitle: "A better school bus experience",
        version: "Current version: Version 2.2.0",
        model_url: "https://prod.spline.design/fZl7lGLovqbsa2Rz/scene.splinecode"
    },
    zh:{
        title: "SchoolBusTime官方网站",
        subTitle: "更好的校车体验",
        version: "当前版本: Version 2.2.0",
        model_url: "https://prod.spline.design/tm70XT9GbOvm04EO/scene.splinecode"
    },
    ja:{
        title: "SchoolBusTime公式サイト",
        subTitle: "より良いスクールバス体験を",
        version: "現行バージョン: Version 2.2.0",
        model_url: "https://prod.spline.design/VcqGYDzLZoDpiNWb/scene.splinecode"
    }
}

const SchoolBusTime = () => {
    const [text, setText] = useState(textContent.en)

    useEffect(() => {
        const setLanguageBasedContent = () => {
            const language = navigator.language || navigator.userLanguage;
            if (language.startsWith('zh')) {
                setText(textContent.zh);
            } else if (language.startsWith('ja')) {
                setText(textContent.ja);
            } else {
                setText(textContent.en);
            }
        };

        setLanguageBasedContent();
        document.title = text.title;
        updateicon(appLogo);
    }, [text.title]);

    const githubButton = () => {
        window.open("https://github.com/JamesXiaoMo/SchoolBusTime_flutter");
    }

    const discordButton = () => {
        window.open("https://discord.gg/jHq7UXFC");
    }

    const androidButton = () => {
        window.open("https://github.com/JamesXiaoMo/SchoolBusTime_Flutter/releases/download/Version2.2.0/app-release.apk");
    }

    const iosButton = () => {
        window.open("https://apps.apple.com/us/app/schoolbustime/id6587569739");
    }

    return (
        <>
            <Grid container spacing={2} sx={{padding: 5, height: '100vh'}} direction={{xs: 'column', md: 'row'}}>
                <Grid item xs={5} display="flex" alignItems="center" justifySelf={"center"}>
                    <Stack spacing={2} direction={"column"} alignItems={"center"} justifySelf={"center"}>
                        <Avatar alt="logo" src={appLogo} variant="rounded" sx={{ width: 200, height: 200}}/>
                        <Typography variant="h2" align={"center"} fontWeight="bold" >
                            SchoolBusTime
                        </Typography>
                        <Typography variant="h6" align={"center"} fontWeight="normal" >
                            {text.subTitle}
                        </Typography>
                        <Typography variant="h7" align={"center"} fontWeight="normal" >
                            {text.version}
                        </Typography>
                        <Stack spacing={1} direction="row" alignItems={"center"}>
                            <Button
                                onClick={githubButton}
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    fontSize: 15,
                                    fontWeight: "bold",
                            }}
                                startIcon={<Avatar src={githubIcon} sx={{ width: 28, height: 28 }} />}
                            >
                                Github
                            </Button>
                            <Button
                                onClick={discordButton}
                                style={{
                                    backgroundColor: "#6e7cc8",
                                    color: "white",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }}
                                startIcon={<Avatar src={discordIcon} sx={{ width: 28, height: 28 }} />}
                            >
                                Discord
                            </Button>
                            <Button
                                onClick={androidButton}
                                style={{
                                    backgroundColor: "#2fffae",
                                    color: "white",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }}
                                startIcon={<Avatar src={androidIcon} sx={{ width: 28, height: 28 }} />}
                            >
                                Android
                            </Button>
                            <Button
                                onClick={iosButton}
                                style={{
                                    backgroundColor: "#009df9",
                                    color: "white",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }}
                                startIcon={<Avatar src={appstoreIcon} sx={{ width: 28, height: 28 }} />}
                            >
                                iOS
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={7} display='flex' justifyContent="center" sx={{height: '100vh'}} >
                    <Spline scene={text.model_url}/>
                </Grid>
            </Grid>
        </>
    );
}

export default SchoolBusTime;